<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.ID }}</td>
                            <td>{{ item.AccountantDate | display_date('DD-MM-YYYY')}}</td>
                            <td class="text-end">{{ item.FirstCumulation | currency }}</td>
                            <td class="text-end" v-if="adminRole || (paymentRole && !item.Approved)">
                                <v-edit-dialog :return-value.sync="item.IncomeAmount" large lazy persistent @save="saveItem({'ID': item.ID, 'IncomeAmount': item.IncomeAmount})">
                                <div>{{ item.IncomeAmount | currency }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.IncomeAmount" type="number" label="Thu" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end" v-if="paymentRole && item.Approved">{{ item.IncomeAmount | currency}}</td>
                            <td class="text-end" v-if="adminRole || (paymentRole && !item.Approved)">
                                <v-edit-dialog :return-value.sync="item.OutcomeAmount" large lazy persistent @save="saveItem({'ID': item.ID, 'OutcomeAmount': item.OutcomeAmount})">
                                <div>{{ item.OutcomeAmount | currency }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.OutcomeAmount" type="number" label="Chi" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end" v-if="paymentRole && item.Approved">{{ item.OutcomeAmount | currency}}</td>
                            <td>{{ item.OutcomeReason }}</td>
                            <td class="text-end">{{ item.Cumulation | currency }}</td>
                            <td>{{ item.Note }}</td>
                            <td class="text-center">
                                <v-icon v-if="adminRole && !item.Approved" medium class="mr-2" @click="saveItem({'ID': item.ID, 'Approved': 1})">fa-check</v-icon>
                                <v-icon v-if="adminRole || (paymentRole && !item.Approved)" medium class="mr-2" @click="gotoDetail(item.ID)">fa-edit</v-icon>
                                <v-icon v-if="adminRole || (paymentRole && !item.Approved)" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr class="total-summary-td">
                            <td colspan="3" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.IncomeAmount | currency}}</td>
                            <td class="text-end font-weight-bold">{{ summary.OutcomeAmount | currency}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            headers: [
                {
                    text: "ID",
                    value: "ID",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Ngày",
                    value: "AccountantDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Tồn đầu kỳ",
                    value: "FirstCumulation",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Thu",
                    value: "IncomeAmount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Chi",
                    value: "OutcomeAmount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Lý do chi",
                    value: "OutcomeReason",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Tồn cuối kỳ",
                    value: "Cumulation",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Ghi chú",
                    value: "Note",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center"
                }
            ],
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "accountantcumulationvnd_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.accountant_cumulation_vnd.all.data,
            total_rows: state => state.accountant_cumulation_vnd.all.total,
            summary: state => state.accountant_cumulation_vnd.all.summary,
            loading: state => state.accountant_cumulation_vnd.loading
        }),
        tableConfig() {
            return {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("accountant_cumulation_vnd/getList", this.tableConfig);
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "AccountantCumulationVNDDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết luồng thu/chi (₫) #${id}`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$router.push({
                name: "AccountantCumulationVNDDetail",
                params: {
                    Pid: 'tao-moi',
                    title: `Tạo mới luồng thu/chi (₫)`
                }
            });
        },
        saveItem(obj) {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("accountant_cumulation_vnd/setDetail", {param: this.tableConfig, data: obj, id: obj.ID});
        },
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("accountant_cumulation_vnd/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
